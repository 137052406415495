.card {
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    color: #212121;
    padding: 12px;
    /*display: block;*/
    margin: 10px 0;
    margin-left: auto;
    margin-right: auto;

    &.black-card {
        background: #212121;

        h3.preco-produto-card,
        h4 {
            color: #eee;
        }

        &>.card-image {
            border: 3px solid #414141;
            background-color: #414141;
        }
    }


    &>.card-image {
        height: 250px;
        width: 400px;
        margin-top: 10px;
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        border: 3px solid #f8f8f8;
        background-color: #f8f8f8;
        text-align: center;
        overflow: hidden;

        &>img {
            max-height: 250px;
            width: auto !important;

            &.none-image {
                margin-top: -18px;
                height: auto;
                width: 100%;
            }
        }
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        transition: 0.3s;
    }
}
/* ------------------------------------------------------------------- */
/* Color #ffb400
---------------------------------------------------------------------- */
div.datedropper:before,
div.datedropper .pick-submit,
div.datedropper .pick-lg-b .pick-sl:before,
div.datedropper .pick-m,
div.datedropper .pick-lg-h {
  background-color: #ffb400 !important;
}

div.datedropper .pick-y.pick-jump,
div.datedropper .pick li span,
div.datedropper .pick-lg-b .pick-wke,
div.datedropper .pick-btn {
  color: #ffb400 !important;
}

.comment-by-listing a:hover,
.browse-all-user-listings a i,
.hosted-by-title h4 a:hover,
.style-2 .trigger.active a,
.style-2 .ui-accordion .ui-accordion-header-active:hover,
.style-2 .ui-accordion .ui-accordion-header-active,
#posts-nav li a:hover,
.plan.featured .listing-badge,
.post-content h3 a:hover,
.add-review-photos i,
.show-more-button i,
.listing-details-sidebar li a,
.star-rating .rating-counter a:hover,
.more-search-options-trigger:after,
.header-widget .sign-in:hover,
#footer a,
#footer .footer-links li a:hover,
#navigation.style-1 .current,
#navigation.style-1 ul li:hover a,
.user-menu.active .user-name:after,
.user-menu:hover .user-name:after,
.user-menu.active .user-name,
.user-menu:hover .user-name,
.main-search-input-item.location a:hover,
.input-with-icon.location a i:hover,
.panel-dropdown a:after,
.post-content a.read-more,
.post-meta li a:hover,
.widget-text h5 a:hover,
.about-author a,
a.button.border.white:hover,
.icon-box-2 i,
a.button.border,
.style-2 .ui-accordion .ui-accordion-header:hover,
.style-2 .trigger a:hover,
.plan.featured .listing-badges .featured,
.list-4 li:before,
.list-3 li:before,
.list-2 li:before,
.list-1 li:before,
.info-box h4,
.testimonial-carousel .slick-slide.slick-active .testimonial:before,
.sign-in-form .tabs-nav li a:hover,
.sign-in-form .tabs-nav li.active a,
.lost_password:hover a,
#top-bar .social-icons li a:hover i,
.listing-share .social-icons li a:hover i,
.agent .social-icons li a:hover i,
#footer .social-icons li a:hover i,
.headline span i {
  color: #ffb400;
}

.qtyTotal,
.mm-menu em.mm-counter,
.category-small-box:hover,
.option-set li a.selected,
.pricing-list-container h4:after,
#backtotop a,
.select-options li:hover,
button.panel-apply,
.layout-switcher a:hover,
.listing-features.checkboxes li:before,
.comment-by a.reply:hover,
.add-review-photos:hover,
.office-address h3:after,
.post-img:before,
button.button,
input[type='button'],
input[type='submit'],
a.button,
a.button.border:hover,
table.basic-table th,
.plan.featured .plan-price,
mark.color,
.style-4 .tabs-nav li.active a,
.style-5 .tabs-nav li.active a,
.dashboard-list-box .button.gray:hover,
.change-photo-btn:hover,
.dashboard-list-box a.rate-review:hover,
input:checked + .slider,
.add-pricing-submenu.button:hover,
.add-pricing-list-item.button:hover,
.custom-zoom-in:hover,
.custom-zoom-out:hover,
#geoLocation:hover,
#streetView:hover,
#scrollEnabling:hover,
#scrollEnabling.enabled,
#mapnav-buttons a:hover,
#sign-in-dialog .mfp-close:hover,
#small-dialog .mfp-close:hover {
  background-color: #ffb400;
}

span.blog-item-tag,
.testimonial-carousel .slick-slide.slick-active .testimonial-box,
.listing-item-container.list-layout span.tag,
.tip,
.mfp-arrow:hover {
  background: #ffb400;
}

.layout-switcher a.active {
  color: #ffb400;
  border-color: #ffb400;
}

#titlebar.listing-titlebar span.listing-tag {
  border-color: #ffb400;
  color: #ffb400;
}

.listing-slider-small .slick-next:hover,
.listing-slider-small .slick-prev:hover,
.listing-slider .slick-next:hover,
.listing-slider .slick-prev:hover {
  background-color: #ffb400;
}

.listing-nav-container.cloned .listing-nav li:first-child a.active,
.listing-nav-container.cloned .listing-nav li:first-child a:hover,
.listing-nav li:first-child a,
.listing-nav li a.active,
.listing-nav li a:hover {
  border-color: #ffb400;
  color: #ffb400;
}

.pricing-list-container h4 {
  color: #ffb400;
  border-color: #ffb400;
}

.sidebar-textbox ul.contact-details li a {
  color: #ffb400;
}

a.button.border {
  color: #ffb400;
  border-color: #ffb400;
}

.trigger.active a,
.ui-accordion .ui-accordion-header-active:hover,
.ui-accordion .ui-accordion-header-active {
  background-color: #ffb400;
  border-color: #ffb400;
}

.numbered.color ol > li::before {
  border-color: #ffb400;
  color: #ffb400;
}

.numbered.color.filled ol > li::before {
  border-color: #ffb400;
  background-color: #ffb400;
}

.info-box {
  border-top: 2px solid #ffb400;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.95));
  background-color: #ffb400;
  color: #ffb400;
}

.info-box.no-border {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.96), rgba(255, 255, 255, 0.93));
  background-color: #ffb400;
}

.tabs-nav li a:hover {
  border-color: #ffb400;
}

.tabs-nav li a:hover,
.tabs-nav li.active a {
  border-color: #ffb400;
  color: #ffb400;
}

.style-3 .tabs-nav li a:hover,
.style-3 .tabs-nav li.active a {
  border-color: #ffb400;
  background-color: #ffb400;
}

.checkboxes input[type='checkbox']:checked + label:before {
  background-color: #ffb400;
  border-color: #ffb400;
}

.listing-item-container.compact .listing-item-content span.tag {
  background-color: #ffb400;
}

.dashboard-nav ul li.active,
.dashboard-nav ul li:hover {
  border-color: #ffb400;
}

.dashboard-list-box .comment-by-listing a:hover {
  color: #ffb400;
}

.opening-day:hover h5 {
  color: #ffb400 !important;
}

.map-box h4 a:hover {
  color: #ffb400;
}
.infoBox-close:hover {
  background-color: #ffb400;
  -webkit-text-stroke: 1px #ffb400;
}

.cluster-visible {
  background-color: #ffb400;
}

.cluster-visible:before {
  border: 7px solid #ffb400;
  box-shadow: inset 0 0 0 4px #ffb400;
}

.marker-arrow {
  border-color: #ffb400 transparent transparent;
}

.face.front {
  border-color: #ffb400;
  color: #ffb400;
}

.face.back {
  background: #ffb400;
  border-color: #ffb400;
}

.custom-zoom-in:hover:before,
.custom-zoom-out:hover:before {
  -webkit-text-stroke: 1px #ffb400;
}

.category-box-btn:hover {
  background-color: #ffb400;
  border-color: #ffb400;
}

.message-bubble.me .message-text {
  color: #ffb400;
  background-color: rgba(255, 180, 0, 0.05);
}

.message-bubble.me .message-text:before {
  color: rgba(255, 180, 0, 0.05);
}

.opening-hours.summary li:hover,
.opening-hours.summary li.total-costs span {
  color: #ffb400;
}
.payment-tab-trigger > input:checked ~ label::before {
  border-color: #ffb400;
}
.payment-tab-trigger > input:checked ~ label::after {
  background-color: #ffb400;
}

#voll-message-input textarea {
  min-height: 56px;
  padding: 10px 0 !important;
}

#voll-contact-widget * {
  box-sizing: border-box;
  margin-top: 0px;
}

.voll-container,
.voll-container > div:not(.bubble) {
  background: #e0e0e0;
}

@media only screen and (max-width: 800px) {
  #voll-float-button {
    margin-bottom: 46px;
    margin-right: -10px;
    z-index: 999 !important;
  }

  #voll-contact-widget .voll-contact-widget-inner {
    float: none !important;
    width: auto !important;
    margin-right: 0px !important;
  }

  #voll-contact-widget {
    bottom: 60px !important;
  }
}

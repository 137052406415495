.shimmer {
    background: #f6f7f8;
    background: linear-gradient(to right, #f6f7f8 0%, #dddddd 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: cover; 
    display: inline-block;
    position: relative; 
    
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards; 
    animation-fill-mode: forwards; 
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  
  
  @keyframes placeholderShimmer {
    0% {
      background-position: -110vw 0;
    }
    
    100% {
      background-position: 110vw 0; 
    }
  }
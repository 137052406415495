.imagem-produto {
  .owl-dots {
    position: relative;
    z-index: 99;
    margin-top: -55px !important;
  }

  .owl-stage-outer {
    position: relative;
    z-index: 10;
  }

  // .owl-item {
  //   width: 500px;
  //   height: 500px;
  // }
}

i-feather {
  width: 18px !important;
  padding-top: 4px;
  vertical-align: bottom;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-boxes-filled .path1:before {
  content: "\e94d";
  color: rgb(255, 206, 84);
}
.icon-boxes-filled .path2:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(246, 187, 66);
}
.icon-boxes-filled .path3:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(101, 109, 120);
}
.icon-boxes-filled .path4:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(204, 209, 217);
}
.icon-boxes-filled .path5:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(204, 209, 217);
}
.icon-boxes-filled .path6:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(170, 178, 188);
}
.icon-boxes-filled .path7:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(204, 209, 217);
}
.icon-boxes-filled .path8:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(246, 187, 66);
}
.icon-boxes-filled .path9:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(220, 167, 70);
}
.icon-boxes-filled .path10:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(218, 68, 83);
}
.icon-boxes-filled .path11:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(237, 85, 101);
}
.icon-boxes-outline:before {
  content: "\e958";
}
.icon-money-bag-filled .path1:before {
  content: "\e959";
  color: rgb(201, 136, 91);
}
.icon-money-bag-filled .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(219, 173, 92);
}
.icon-money-bag-filled .path3:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(114, 68, 53);
}
.icon-money-bag-filled .path4:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(155, 94, 52);
}
.icon-money-bag-filled .path5:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(201, 136, 91);
}
.icon-money-bag-filled .path6:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(219, 173, 92);
}
.icon-money-bag-filled .path7:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(225, 235, 240);
}
.icon-money-bag-filled .path8:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(225, 235, 240);
}
.icon-money-bag-filled .path9:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(255, 245, 245);
}
.icon-money-bag-filled .path10:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(255, 245, 245);
}
.icon-money-bag-outline:before {
  content: "\e963";
}
.icon-address-filled .path1:before {
  content: "\e900";
  color: rgb(204, 155, 124);
}
.icon-address-filled .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(191, 130, 91);
}
.icon-address-filled .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(254, 228, 111);
}
.icon-address-filled .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(219, 239, 250);
}
.icon-address-filled .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(254, 211, 48);
}
.icon-address-filled .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(203, 232, 248);
}
.icon-address-filled .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(151, 222, 61);
}
.icon-address-filled .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(89, 195, 106);
}
.icon-cart-filled .path1:before {
  content: "\e908";
  color: rgb(48, 60, 66);
}
.icon-cart-filled .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}
.icon-cart-filled .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-cart-filled .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(48, 60, 66);
}
.icon-cart-filled .path5:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(48, 60, 66);
}
.icon-cart-filled .path6:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cart-outline:before {
  content: "\e90e";
}
.icon-credit-card-filled .path1:before {
  content: "\e90f";
  color: rgb(107, 190, 161);
}
.icon-credit-card-filled .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path3:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path4:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path5:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path6:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(240, 196, 27);
}
.icon-credit-card-filled .path7:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(243, 213, 92);
}
.icon-credit-card-filled .path8:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(240, 196, 27);
}
.icon-credit-card-filled .path9:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(243, 213, 92);
}
.icon-credit-card-filled .path10:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(243, 213, 92);
}
.icon-credit-card-filled .path11:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(243, 213, 92);
}
.icon-credit-card-filled .path12:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(243, 213, 92);
}
.icon-credit-card-filled .path13:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path14:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path15:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path16:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path17:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path18:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-card-filled .path19:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(73, 134, 121);
}
.icon-credit-cards-filled .path1:before {
  content: "\e922";
  color: rgb(255, 183, 130);
}
.icon-credit-cards-filled .path2:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(222, 76, 60);
}
.icon-credit-cards-filled .path3:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(122, 73, 48);
}
.icon-credit-cards-filled .path4:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(67, 152, 209);
}
.icon-credit-cards-filled .path5:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(62, 140, 199);
}
.icon-credit-cards-filled .path6:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path7:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path8:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path9:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path10:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path11:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path12:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path13:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path14:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path15:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(94, 179, 209);
}
.icon-credit-cards-filled .path16:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(136, 179, 55);
}
.icon-credit-cards-filled .path17:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 183, 130);
}
.icon-credit-cards-filled .path18:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(107, 150, 42);
}
.icon-credit-cards-filled .path19:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(253, 182, 47);
}
.icon-credit-cards-filled .path20:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(253, 123, 47);
}
.icon-credit-cards-filled .path21:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(253, 123, 47);
}
.icon-credit-cards-filled .path22:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(242, 164, 111);
}
.icon-form-filled .path1:before {
  content: "\e938";
  color: rgb(240, 247, 255);
}
.icon-form-filled .path2:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(223, 231, 244);
}
.icon-form-filled .path3:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(58, 170, 255);
}
.icon-form-filled .path4:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(223, 231, 244);
}
.icon-form-filled .path5:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(223, 231, 244);
}
.icon-form-filled .path6:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(71, 79, 84);
}
.icon-form-filled .path7:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(199, 207, 225);
}
.icon-form-filled .path8:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(199, 207, 225);
}
.icon-form-filled .path9:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(199, 207, 225);
}
.icon-form-filled .path10:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(199, 207, 225);
}
.icon-home:before {
  content: "\e942";
}
.icon-home-outline:before {
  content: "\e943";
}
.icon-login-outline:before {
  content: "\e944";
}
.icon-tag-discount-filled .path1:before {
  content: "\e945";
  color: rgb(112, 116, 135);
}
.icon-tag-discount-filled .path2:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 220, 100);
}
.icon-tag-discount-filled .path3:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(255, 200, 80);
}
.icon-tag-discount-filled .path4:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(127, 132, 153);
}
.icon-tag-discount-filled .path5:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-tag-discount-filled .path6:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-tag-discount-filled .path7:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-tag-discount-outline:before {
  content: "\e94c";
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import './assets/styles/index.scss';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@angular/material/theming';
@include mat-core();

$primary: mat-palette($mat-amber, 600);
$primary-you: #f9d75c;
$primary-text-you: #996c01;

$accent: mat-palette($mat-teal, 900);
$theme: mat-light-theme($primary, $accent);
@include angular-material-theme($theme);

img {
  object-fit: contain;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

// media query para telas abaixo de 720px
@media (max-width: 720px) {
  mat-dialog-container {
    position: fixed;
    top: 20%;
    right: 0;
    border: 0;
    padding: 4px !important;
  }
}

.material-symbols-outlined {
  background: transparent !important;
}

app-ads-txt {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: white;
  z-index: 99;
}

h1 {
  color: $primary-text-you;
}

.mat-snack-bar-container {
  .mat-button-wrapper {
    color: var(--primary-color);
  }
}

.mat-card {
  background-color: #fff;
}

mat-card-title,
mat-card-header,
mat-card-content {
  background-color: transparent;
}

.mat-card-header-text {
  margin: 0 !important;
}

.mat-card,
.mat-table,
.dashboard-list-box {
  background: #fff;
}

#dashboard {
  background-color: #f4f4f4;
}

mat-card-title {
  font-weight: 300;
  font-size: 2em;
  margin: 10px 0;
}

.mat-simple-snackbar-action {
  color: #ffb300;
  border: 1px solid #eee;
  border-radius: 4px;
}

.mat-snack-bar-container {
  background: #fff;
  color: #424224;
}

html,
body {
  scroll-behavior: smooth;
  font-family: 'Roboto' !important;
  background: var(--body-background);
}

a {
  text-decoration: none !important;
}

.owl-item {
  padding: 0 5px;
}

.owl-prev,
.owl-next {
  color: #996c01 !important;
  padding: 0 !important;
  background: #ffea9c !important;
  border-radius: 30px !important;
  height: 30px !important;
  width: 30px !important;
  line-height: 30px !important;
}

#wrapper {
  overflow: hidden;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #ffb400;
  color: #fff;
  font-weight: bold;
}

.mat-flat-button.mat-primary[_ngcontent-ats-c322],
.mat-raised-button.mat-primary[_ngcontent-ats-c322],
.mat-fab.mat-primary[_ngcontent-ats-c322],
.mat-mini-fab.mat-primary[_ngcontent-ats-c322] {
  background-color: #ffb400 !important;
  color: white !important;
  font-weight: 500 !important;
}

.capitalize {
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto' !important;
}

input:not(.mat-input-element) {
  &:focus {
    color: #ffb400 !important;
    border: solid 1px !important;
    -webkit-box-shadow: 0px 0px 28px -4px rgba(33, 33, 33, 0.66) !important;
    -moz-box-shadow: 0px 0px 28px -4px rgba(33, 33, 33, 0.66) !important;
    box-shadow: 0px 0px 28px -4px rgba(33, 33, 33, 0.66) !important;
  }
}

.button:disabled {
  background-color: #ccc;
}

.login,
.register {
  margin-top: 20px;
}

.swal2-popup {
  font-size: 1.6rem !important;
}

a {
  cursor: pointer;
}

#not-found i {
  color: #848484;
  font-size: 190px;
  top: 16px;
  position: relative;
}

.mat-raised-button.mat-primary {
  color: #fff;
}

drag-scroll {
  height: 33%;

  img {
    height: 100%;
  }
}

.drag-scroll-content {
  display: block;
  white-space: nowrap;
  width: 100%;
  height: 140px;
  overflow: hidden !important;
  overflow-y: hidden;
}

#titlebar {
  padding: 0;
}

.fill-avaliable {
  width: -webkit-fill-available;
}

.products-box {
  margin-top: 100px;
}

.chip {
  display: inline-block;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #e4e4e4;
  margin-bottom: 5px;
  margin-right: 5px;
}

@media (min-width: 991px) {
  .custom-grid {
    width: 25%;
    display: inline-block;
    padding: 0 10px;
    top: 0;
    margin-top: 0;
    vertical-align: top;
  }
}

@media (max-width: 990px) {
  .custom-grid {
    width: 50%;
    display: inline-block;
    padding: 0 10px;
    top: 0;
    margin-top: 0;
    vertical-align: top;
  }

  .products-box {
    margin: 0 15px;
  }
}

@media (max-width: 540px) {
  .custom-grid {
    width: 50%;
    display: inline-block;
    padding: 0 2px;
    top: 0;
    margin-top: 0;
    vertical-align: top;
  }
}

@media only screen and (max-width: 1240px) {
  .hide-on-mobile {
    display: none !important;
  }

  .show-on-mobile-only {
    display: inherit;
  }

  .section {
    margin-top: 70px;
    background: #f4f4f4;
  }

  button.flat-desing,
  a.flat-desing {
    background: #ffb400;
    color: white;
    font-weight: bold;
    border: none;
    padding: 14px 34px;
    margin: 0 10px;
    display: block;
    border-radius: 10px;
    border-bottom: 4px solid #b98300;
    transition: 0.5s;
    font-size: 1.5em;
  }

  .full-width-on-mobile {
    width: 100%;
    margin: 0 !important;
  }
}

@media only screen and (min-width: 1241px) {
  .hide-on-mobile {
    display: inherit;
  }

  .show-on-mobile-only {
    display: none !important;
  }

  .section {
    padding-top: 70px;
    background: #f4f4f4;
  }
}

button.flat-desing,
a.flat-desing {
  background: #ffb400;
  color: white;
  font-weight: bold;
  border: none;
  padding: 7px 20px;
  margin: 0 10px;
  display: block;
  border-radius: 10px;
  border-bottom: 2px solid #b98300;
  transition: 0.5s;

  &:hover {
    background: #f5b417;
    transition: 0.5s;
  }

  &:active {
    background: #c18b07;
    transition: 0.5s;
    border-bottom: none;
  }
}

.mat-raised-button,
.mat-button,
.mat-flat-button,
.mat-stroked-button,
.mat-fab {
  font-weight: 400;
  font-size: 16px;
}

.mat-raised-button {
  font-size: 16px;
  background-color: $primary-you !important;
  color: #996c01 !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  transition: 0.5s;
}

.mat-raised-button:hover {
  background-color: #f8cb26 !important;
  transition: 0.5s;
}

.button {
  border-radius: 8px !important;
  height: 41px;
  padding: 8px 20px !important;

  &:hover {
    background-color: #c18b07;
    transition: 0.4s;
    color: #fff;
  }
}

a {
  &.button {
    padding: 10px 20px !important;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
